@keyframes placeHolderShimmer{
  0%{
      background-position: -500px 0
  }
  100%{
      background-position: 500px 0
  }
}

.animated-background {
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: linear-gradient(to right, #e9ecef 0%, darken(#e9ecef, 5) 33%, #e9ecef 66%);
  background-size: 1000px;
  position: relative;
}