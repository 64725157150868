@font-face {
  font-family: "Brandon Text";
  src: url("../assets/fonts/brandon-regular.otf");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Brandon Text";
  src: url("../assets/fonts/brandon-bold.otf");
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: "Brandon Text";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/brandon-regular-italic.otf");
}

h1 {
  min-height: $h1-font-size + $headings-margin-bottom;
  font-weight: 600;
}

h2 {
  min-height: $h2-font-size + $headings-margin-bottom;
  font-weight: 600;
}

h3 {
  min-height: $h3-font-size + $headings-margin-bottom;
  font-weight: 600;
}

label {
  font-weight: 600;
}