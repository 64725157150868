// Colors

$theme-colors: (
  "red": #f15a24,
  "black": #38444f,
  "beige": #f3f0ee,
);

$primary: map-get($theme-colors, "black");

// Spacing

$spacer: 1rem;

// Navbar

$navbar-nav-link-padding-x: $spacer;
$navbar-padding-x: $spacer;   
$nav-link-padding-y: $spacer;

// Typography

$font-family-sans-serif:  "Brandon Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !default;

$font-size-base: 1rem;

$h1-font-size: $font-size-base * 2;
$h2-font-size: $font-size-base * 1.6;
$h3-font-size: $font-size-base * 1.3;
$h4-font-size: $font-size-base * 1;
$h5-font-size: $font-size-base * 1;

// Form

$form-group-margin-bottom: 2rem;
$label-margin-bottom: 0.75rem;

// Button 

$btn-font-size: $font-size-base * 0.85;  
$btn-font-size-lg: $font-size-base;

$btn-font-weight: 600;

// Table
$table-cell-padding: $spacer;