
.nav-link.active {
    background-color: #f3f0ee;
}

.nav-item:hover {
    background-color: #f7f6f5;
}

.toggle {
    cursor: pointer;
}
