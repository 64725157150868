.Codes {
  list-style-type: none;
  padding-inline-start: 0;
  column-count: 4;

  &__code {
    break-inside: avoid;
  }

  &__border {
    border: 1px solid red;
  }
}