.Paginator__controls {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.Paginator__controls_link {
  margin: 0 0.25rem;
}
