.Login {
  width: 360px;
  margin: 0 auto;

  &__form {
    width: 440px;
  }

  .marginfix {
    margin-top: -4px;
  }
}
